











































































































































































































































































































































































import { defineComponent, useContext, ref } from '@nuxtjs/composition-api';
import { Breadcrumb } from '~/modules/catalog/types';
import Breadcrumbs from '~/components/Layouts/Breadcrumbs/index.vue';

export default defineComponent({
  components: {
    Breadcrumbs,
  },
  setup() {
    const { localePath, app } = useContext();
    const pageRoute = (page) => app.localeRoute({ name: page });

    const pageTitle = ref('Privacy Policy');

    const breadcrumbs = ref<Breadcrumb[]>([
      {
        text: 'privacy policy',
        link: localePath('/support/privacy-policy'),
      },
    ]);

    const prefixBreadcrumbs = ref<Breadcrumb[]>([
      {
        text: 'home',
        link: localePath('/'),
      },
    ]);

    const pivacyPolicy = {
      text: '',
      link: pageRoute('support/privacy-policy'),
    };

    return {
      pageTitle,
      prefixBreadcrumbs,
      breadcrumbs,
      pivacyPolicy,
    };
  },
});
